import { Layout, Card, Page, ResourceList, ResourceItem, TextStyle, Pagination, Avatar } from "@shopify/polaris";
import React, { useEffect, useState } from "react";
import {
  FolderDownMajor,
  RefreshMajor,
  NotificationMajor
} from '@shopify/polaris-icons';

import MD5 from "crypto-js/md5";


function exportToCsv(filename: string, rows: any[]) {
  var processRow = function (row: any) {
      var finalVal = '';
      for (var j = 0; j < row.length; j++) {
          var innerValue = row[j] === null ? '' : row[j].toString();
          if (row[j] instanceof Date) {
              innerValue = row[j].toLocaleString();
          };
          var result = innerValue.replace(/"/g, '""');
          if (result.search(/("|,|\n)/g) >= 0)
              result = '"' + result + '"';
          if (j > 0)
              finalVal += ',';
          finalVal += result;
      }
      return finalVal + '\n';
  };

  var csvFile = '';
  for (var i = 0; i < rows.length; i++) {
      csvFile += processRow(rows[i]);
  }

  var blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' });
  if ((navigator as any).msSaveBlob) { // IE 10+
      (navigator as any).msSaveBlob(blob, filename);
  } else {
      var link = document.createElement("a");
      if (link.download !== undefined) { // feature detection
          // Browsers that support HTML5 download attribute
          var url = URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute("download", filename);
          link.style.visibility = 'hidden';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
      }
  }
}

const Waitlists = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [calendars, setCalendars] = useState([]);
  const [appointmentTypes, setAppointmentTypes] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [waitlists, setWaitlists] = useState([]);


  const fetchWaitlists = async (page: number) => {
    setIsLoading(true);
    const url = '/waitlists/query?page=' + page;
    const response = await fetch(url);
    const newWaitlists = await response.json();
    setWaitlists(newWaitlists);
    setIsLoading(false);
    console.log(newWaitlists);
  };

  useEffect(() => {
    const run = async () => {
      if (!calendars || calendars.length === 0) {
        setCalendars(await (await fetch('/acuity/calendars')).json());
      }
      if (!appointmentTypes || appointmentTypes.length === 0) {
        setAppointmentTypes(await (await fetch('/acuity/appointment-types')).json());
      }
      setIsLoading(true);
      await fetchWaitlists(currentPage);
    }
    run();
  }, [currentPage]);
  return (
    <Page title={"Waitlist Details"} primaryAction={ {
      content: 'Refresh',
      icon: RefreshMajor,
      onAction: () => fetchWaitlists(currentPage),
      disabled: isLoading,
      
    }}
    actionGroups={[
      {
        title: 'Export',
        actions: [
          {
            content: 'Export logs',
            icon: FolderDownMajor,
            accessibilityLabel: 'Export logs',
            disabled: isLoading,
            onAction: async () => {
              setIsLoading(true);
              const url = '/waitlists/logs?limit=20000';
              const response = await fetch(url);
              const data = await response.json();
              const headers = ['id', 'type', 'email', 'phone', 'calendarIDs', 'appointmentTypeId', 'message', 'created_at'];
              const rows = [headers]
              data.forEach((row: any) => {
                rows.push([
                  row.id || '',
                  row.type || '',
                  row.email || '',
                  row.phone || '',
                  row.calendarIDs || '',
                  row.appointmentTypeId || '',
                  row.message || '',
                  row.created_at || '',
                ])
              })
              exportToCsv(`studs_logs_export_${(new Date()).toISOString().split(':').join('_')}.csv`, rows);
              setIsLoading(false);
            }
          },
          {
            content: 'Export waitlist details',
            icon: FolderDownMajor,
            accessibilityLabel: 'Export waitlist details',
            disabled: isLoading,
            onAction: async () => {
              setIsLoading(true);
              const url = '/waitlists/query?limit=10000';
              const response = await fetch(url);
              const data = await response.json();
              const headers = ['id', 'email', 'phone', 'calendarIds', 'calendarName', 'appointmentTypeId', 'appointmentTypeName', 'addons', 'waitlist_date', 'waitlist_month', 'created_at', 'updated_at', 'notified_at', 'deleted_at', 'deletion_reason', 'appointment_id', 'appointment_date', 'appointment_calendar_id', 'appointment_type_id', 'appointment_booked_status'];
              const rows = [headers]
              data.forEach((row: any) => {
                const calendarIds = `${row.calendarIds}`.split(',');
                let calendarName: any = (calendars||[]).find((cal: any) => calendarIds.indexOf(`${cal.id}`) !== -1)
                calendarName = calendarName ? calendarName.name : 'Unknown';
                let appointmentTypeName: any = (appointmentTypes || []).find((v: any) => `${v.id}` === `${row.id}`)
                appointmentTypeName = appointmentTypeName ? appointmentTypeName.name : 'Unknown';
                rows.push([
                  row.id || '',
                  row.email || '',
                  row.phone || '',
                  (row.calendarIds || '').split(',').map((v: string) => ~~v).sort((a: number, b: number) => a - b).join(','),
                  calendarName || '',
                  row.appointmentTypeId || '',
                  appointmentTypeName || '',
                  row.addons || '',
                  row.waitlist_date || '',
                  row.month || '',
                  row.created_at || '',
                  row.updated_at || '',
                  row.notified_at || '',
                  row.deleted_at || '',
                  row.deletion_reason || '',
                  row.appointment_id || '',
                  row.appointment_date || '',
                  row.appointment_calendar_id || '',
                  row.appointment_type_id || '',
                  row.appointment_booked_status || '',
                ])
              })
              exportToCsv(`studs_waitlist_export_${(new Date()).toISOString().split(':').join('_')}.csv`, rows);
              setIsLoading(false);
            }
          },
        ],
      },
    ]}
    secondaryActions={[
      {
        content: "Force notify",
        onAction: () => fetchWaitlists(currentPage),
        disabled: isLoading,
        icon: NotificationMajor
      }
    ]}
    >
      <Layout>
        <Layout.Section>
          <Card>
            <ResourceList
              resourceName={{
                singular: "Waitlist",
                plural: "Waitlists",
              }}
              items={waitlists}
              renderItem={(item: any) => {
                return (
                  <ResourceItem
                    id={item.id}
                    url={'#'}
                    media={
                      <Avatar
                        customer
                        size="medium"
                        source={`https://www.gravatar.com/avatar/${MD5(`${item.email}`.toLowerCase().trim()).toString()}?d=404`}
                      />
                    }
                  >
                    <h3>
                      <TextStyle variation="strong">{item.email} ({item.phone})</TextStyle> &mdash; <TextStyle variation={item.deleted_at || !item.notified_at ? 'subdued' : 'positive'}>{item.notified_at ? `Notified on ${new Date(item.notified_at).toLocaleDateString()}` : 'Not Notified Yet'}</TextStyle>
                    </h3>
                    <h4>
                      <TextStyle variation="subdued">{appointmentTypes.filter((v:any) => v.id === item.appointmentTypeId).map((v:any) => v.name).pop() || `Unknown appointment type: ${item.appointmentTypeId}`}</TextStyle> &mdash; <TextStyle variation="subdued">{(new Date(item.waitlist_date)).toLocaleDateString()}</TextStyle>
                    </h4>
                    <div>{calendars.filter((v:any) => (item.calendarIds||'').split(',').indexOf(`${v.id}`) !== -1).map((v: any) => v.name).pop() || `Unknown location: ${item.calendarIds} `}</div>
                  </ResourceItem>
                );
              }}
              loading={isLoading}
            />
             <Pagination
              hasPrevious={currentPage > 1}
              onPrevious={() => {
                const newPage = Math.max(currentPage - 1, 1);
                setCurrentPage(newPage);
                fetchWaitlists(newPage);
                setCurrentPage(newPage);
              }}
              hasNext
              onNext={() => {
                const newPage = Math.max(currentPage + 1, 1)
                console.log('Next', {newPage});

                fetchWaitlists(newPage);
                setCurrentPage(newPage);
              }}
            />
            
          </Card>
        </Layout.Section>
      </Layout>
    </Page>
  );
}

export default Waitlists;