import { Layout, Card, Page, ResourceList, ResourceItem, TextStyle, SettingToggle, Collapsible, Button, Stack, Pagination, DescriptionList, List, EmptyState, Badge } from "@shopify/polaris";
import React, { useCallback, useEffect, useState } from "react";
import { graphql, restApi } from "../utils/session";
import {
  FolderDownMajor,
  RefreshMajor,
  ReplayMinor,
  NotificationMajor
} from '@shopify/polaris-icons';
import DiscountImage from '../svg/undraw_discount_d-4-bd.svg';
import DiscountModal from "../components/discountModal";


const Discounts = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [config, setConfig] = useState(null);
  const [discounts, setDiscounts] = useState<any[]>();
  const [discountsUsage, setDiscountsUsage] = useState<Record<string, number>>({});
  const [pageInfo, setPageinfo] = useState<{
    endCursor?: string;
    startCursor?: string;
    hasNextPage: boolean;
    hasPreviousPage: boolean;
  }>({
    hasNextPage: false,
    hasPreviousPage: false,
  });
  const [direction, setDirection] = useState<'after'|'before'>('after');
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedMetafieldKey, setSelectedMetafieldKey] = useState<string>();

  const fetchDiscounts = useCallback(async (remain:boolean = false) => {
    setIsLoading(true);
    try {
      const usage: {
        coupon_code: string;
        count: number;
      }[] = await(await fetch('/waitlists/discounts_usage?limit=1000')).json();
      const newUsage:Record<string, number> = {}
      usage.forEach((item: any) => {
        newUsage[`${item.coupon_code}`.toUpperCase()] = item.count;
      });
      setDiscountsUsage(newUsage);
    } catch (e) {
      console.error(e);
    }
    const cursorToUse = remain ? null : (direction === 'after' ? pageInfo.endCursor : pageInfo.startCursor);
    const query = `{
      shop {
        metafields(${direction == 'after' ? 'first' : 'last'}: 250, ${ cursorToUse ? `${direction}: "${cursorToUse}",` : ''} namespace: "pact_acuity_discounts", reverse: true) {
          pageInfo {
            endCursor
            startCursor
            hasNextPage
            hasPreviousPage
          }
          nodes {
            description
            id
            key
            value
            legacyResourceId
          }
      }
    }}`
    const response = await graphql(query);
    const newDiscounts = response.data.shop.metafields.nodes;
    setDiscounts(newDiscounts);
    setPageinfo(response.data.shop.metafields.pageInfo);
    setIsLoading(false);
  }, [direction, pageInfo])
  useEffect(() => {
    const run = async () => {
      setIsLoading(true);
      if (!config) {
        setConfig(await (await fetch('/acuity/app-config')).json());
      }
      fetchDiscounts();
      setIsLoading(false);
    }
    run();
  }, []);

  const onPrevious = () => {
    setDirection('before');
    fetchDiscounts();
  }
  const onNext = () => {
    setDirection('after');
    fetchDiscounts();
  }

  return (
    <Page title={"Discounts"} primaryAction={ {
      content: 'New discount',
      icon: RefreshMajor,
      onAction: () => {
        setSelectedMetafieldKey('');
        setModalOpen(true);
      },
      disabled: isLoading,
    }}
    >
      <DiscountModal
        metafieldKey={selectedMetafieldKey}
        open={modalOpen}
        onClose={() => {
          setModalOpen(false)
          fetchDiscounts(true);
        }}
      />
      <Layout>
        <Layout.Section>
          <Card>
            {!discounts || discounts?.length ? <><ResourceList
              resourceName={{
                singular: "Discount",
                plural: "Discounts",
              }}
              items={discounts || []}
              renderItem={(item: any) => {
                let data: any = {};
                try {
                  data = JSON.parse(item.value);
                } catch (e) {
                  console.error(e);
                }
                return (
                  <ResourceItem
                    id={item.id}
                    onClick={() => {
                      setSelectedMetafieldKey(item.key)
                      setModalOpen(true);
                    }}
                  >
                    <h3>
                      <Stack>
                        <Stack.Item>
                          <TextStyle variation="strong">{item.key}</TextStyle>
                        </Stack.Item>
                        <Stack.Item>
                          {data?.active && <Badge status="success">Active</Badge>}
                        </Stack.Item>
                      </Stack>
                    </h3>
                    <h4>
                      <TextStyle variation="subdued">{data.calendars && data.calendars.length ? data.calendars.join(', ') : 'All Locations'}</TextStyle>
                    </h4>
                    <h4>
                      <TextStyle variation="subdued">{data.types && data.types.length ? data.types.join(', ') : 'All Types'}</TextStyle>
                    </h4>
                    {Object.keys((data && data.daysOfWeek) || {}).length ? 
                      <h4>
                        <TextStyle variation="subdued">Only valid {Object.keys(data.daysOfWeek).map(day => `${day}${data.daysOfWeek[day]?.max ? ` (until ${data.daysOfWeek[day]?.max} slot${data.daysOfWeek[day]?.max > 1 ? 's' : ''} remain)` : ''}`).join(', ')}</TextStyle>
                      </h4> : null}
                    <TextStyle variation="subdued">{discountsUsage[item.key] ? `Used ${discountsUsage[item.key]} times` : 'No recorded uses yet'}</TextStyle>
                  </ResourceItem>
                );
              }}
              loading={isLoading}
            />
             {pageInfo.hasNextPage || pageInfo.hasPreviousPage ? <Pagination
              hasPrevious={pageInfo.hasPreviousPage}
              onPrevious={onPrevious}
              hasNext={pageInfo.hasNextPage}
              onNext={onNext}
            /> : null}</> : 
              <EmptyState 
                action={{content: 'Add discount', onAction: () => {
                  setSelectedMetafieldKey('')
                  setModalOpen(true);
                }}}
                image={DiscountImage}
                imageContained
                heading="There are no discounts to list">
                <p>Discounts are used to give your customers a discount on their appointment.</p>
              </EmptyState>}
          </Card>
        </Layout.Section>
      </Layout>
    </Page>
  );
}

export default Discounts;