import { Layout, Card, Page, Popover, Button, ActionList } from "@shopify/polaris";
import React, { useEffect, useMemo, useState } from "react";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';


const Home = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [statsData, setStats] = useState<any>(null);

  const fetchData = async (refresh = false) => {
    setIsLoading(true);
    const url = `/waitlists/stats`;
    const response = await fetch(url);
    const data = await response.json();
    setStats(data);
    setIsLoading(false);
  };
  const dataByType: any = useMemo(() => {
    const types:Record<string, {
      x_axis: string;
      z_axis: string;
      y_axis: number;
      name: string;
    }[]> = {};
    (statsData || []).forEach((item: any) => {
      if (!types[item.name]) {
        types[item.name] = [];
      }
      types[item.name].push(item);
    });
    return types;
  }, [statsData])
  useEffect(() => {
    fetchData();
  }, []);
  const last60days = useMemo(() => {
    const days = [];
    for (let i = 0; i < 60; i++) {
      days.push(new Date(new Date().setDate(new Date().getDate() - i)).toDateString());
    }
    return days.reverse();
  }, []);

  const knownTypes = useMemo(() => {
    return Object.keys(dataByType || {});
  }, [dataByType]);
  
  const rechartsDataByType = useMemo(() => {
    console.log({dataByType, knownTypes, last60days})
    const finalizedData: any = {};
    knownTypes.forEach((type) => {
      const data = dataByType[type];
      const axisNames = data.map((item: any) => item.z_axis).filter((value: any, index: any, self: any) => self.indexOf(value) === index).sort((a: any, b: any) => a.localeCompare(b));
      last60days.forEach(day => {
        const newData: any = {
          name: day,
          total: 0
        };
        data.filter((item: any) => (new Date(`${item.x_axis}`.split('Z').join(''))).toDateString() === day).forEach((item: any) => {
          newData[item.z_axis] = item.y_axis;
          newData.total += item.y_axis;
        });
        axisNames.forEach((axisName: any) => {
          if (!newData[axisName]) {
            newData[axisName] = 0;
          }
        });
        if (!finalizedData[type]) {
          finalizedData[type] = {
            axisNames,
            data: []
          };
        }
        finalizedData[type].data.push(newData);
      })
    });
    return finalizedData;
  }, [dataByType, knownTypes, last60days]);
  console.log({rechartsDataByType})

  const stringToColor = (str: string) => {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    let color = '#';
    for (let i = 0; i < 3; i++) {
      const value = (hash >> (i * 8)) & 0xff;
      color += ('00' + value.toString(16)).substr(-2);
    }
    return color;
  }

  const statsCardsConfig = [
    {
      title: 'Total Bookings by Calendar',
      text: 'Total appointments, aggregated by their creation date (as opposed to the date of booking) and calendar',
      type: 'APPOINTMENT_CREATED_BY_CALENDAR'
    },
    {
      title: 'Total Bookings by Type',
      text: 'Total appointments, aggregated by their creation date (as opposed to the date of booking) and type',
      type: 'APPOINTMENT_CREATED_BY_TYPE'
    },
    {
      title: 'Total Appointments by Calendar',
      text: 'Total appointments, aggregated by their creation date (as opposed to the date of booking) and calendar',
      type: 'APPOINTMENT_DATE_BY_CALENDAR'
    },
    {
      title: 'Total Appointments by Type',
      text: 'Total appointments, aggregated by their creation date (as opposed to the date of booking) and type',
      type: 'APPOINTMENT_DATE_BY_TYPE'
    },
    
  ]

  const [selectedTypeConfig, setSelectedTypeConfig] = useState<any>({});
  const [openedPopover, setOpenedPopover] = useState<any>(null);

  return (
    <Page title={"Studs Acuity"}>
      <Layout>
        <Layout.Section>
          {statsCardsConfig.map((config) => {
            return (
              <Card sectioned>
                <Card.Header
                  title={config.title}
                >
                  <Popover
                    active={openedPopover === config.type}
                    activator={
                      <Button disclosure plain onClick={() => setOpenedPopover(config.type)}>
                        {!selectedTypeConfig[config.type] || selectedTypeConfig[config.type] === 'total' ? 'Filter' : selectedTypeConfig[config.type]}
                      </Button>
                    }
                    onClose={() => setOpenedPopover(null)}
                  >
                    <ActionList items={[
                      {
                        content: 'Total',
                        onAction: () => {
                          setSelectedTypeConfig({
                            ...selectedTypeConfig,
                            [config.type]: 'total'
                          });
                          setOpenedPopover(null);
                        }
                      },
                      ...(rechartsDataByType?.[config.type]?.axisNames || []).map((name: string) => ({
                        content: name,
                        onAction: () => {
                          setSelectedTypeConfig({
                            ...selectedTypeConfig,
                            [config.type]: name
                          });
                          setOpenedPopover(null);
                        }
                      }))
                    ]} />
                  </Popover>
                </Card.Header>
                <Card.Section>
                  <p>{config.text}</p>
                </Card.Section>
                <LineChart
                    width={850}
                    height={300}
                    data={rechartsDataByType?.[config.type]?.data || []}
                    margin={{
                      top: 5,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}
                  >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  {/* {(rechartsDataByType?.['APPOINTMENT_CREATED_BY_CALENDAR']?.axisNames || []).map((name: string) => (
                    <Line type="monotone" dataKey={name} stroke={stringToColor(name)} />
                  ))} */}
                  <Line type="monotone" dataKey={selectedTypeConfig[config.type] || 'total'} stroke="#8884d8" />
                  
                </LineChart>
              </Card>
            )
          })}
        </Layout.Section>
      </Layout>
    </Page>
  );
}

export default Home;