import { Layout, Card, Page, DescriptionList, List } from "@shopify/polaris";
import React, { useEffect, useState } from "react";
import {
  ReplayMinor,
} from '@shopify/polaris-icons';

const CachePage = () => {
  const [isFetchingCacheStatus, setIsFetchingCacheStatus] = useState(true);
  const [cacheData, setCacheData] = useState<any>(null);
  
  const fetchCacheStatus = async (refresh = false) => {
    setIsFetchingCacheStatus(true);
    const url = `/acuity/app-config${refresh ? '?refresh=true' : ''}`;
    const response = await fetch(url);
    const cacheStatus = await response.json();
    setCacheData(cacheStatus);
    setIsFetchingCacheStatus(false);
  };

  useEffect(() => {
    fetchCacheStatus();
  }, []);

  return (
    <Page title={"Cache Management"}>
      <Layout>
        <Layout.Section>
          <Card title="Cache Status" sectioned primaryFooterAction={{
            content: 'Manually Reset Cache',
            icon: ReplayMinor,
            destructive: true,
            onAction: () => fetchCacheStatus(true),
            disabled: isFetchingCacheStatus,
            loading: isFetchingCacheStatus
          }}>
            {cacheData && <DescriptionList
            items={[
              {
                term: 'Last update',
                description:
                  `${new Date(cacheData.updatedAt).toLocaleString()} via ${cacheData.updatedMethod === 'scheduled' ? 'scheduled task' : 'manual refresh'}`,
              },
              {
                term: 'Counts & Details',
                description: <List type="bullet">
                <List.Item>{cacheData?.calendars?.length || 0} Calendars</List.Item>
                <List.Item>{cacheData?.appointmentTypes?.length || 0} Appointment Types</List.Item>
                <List.Item>{cacheData?.appointmentAddons?.length || 0} Appointment Addons</List.Item>
                <List.Item>{cacheData?.forms?.length || 0} Forms</List.Item>
              </List>
              }
            ]}
          />}
          </Card>
        </Layout.Section>
      </Layout>
    </Page>
  );
}

export default CachePage;