import React from 'react';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import '@shopify/polaris/build/esm/styles.css';
import enTranslations from '@shopify/polaris/locales/en.json';
import {AppProvider, Frame, Navigation} from '@shopify/polaris';
import InstallTemplate from './templates/install'
import Guard from './components/guard';
import Home from './templates/home';
import {DiscountsMinor, HomeMinor, ClockMinor, LocationsMinor, RefreshMinor} from '@shopify/polaris-icons';
import Locations from './templates/locations';
import { getSessionKey, getShop } from './utils/session';
import CachePage from './templates/cache';
import Waitlists from './templates/waitlists';
import Discounts from './templates/discounts';

function App() {
  const shopSuffix = `?shop=${encodeURIComponent(getShop() || '')}#session=${encodeURIComponent(getSessionKey() || '')}`
  const navigation = (
    <Navigation location={window.location.pathname}>
      <Navigation.Section
        items={[
          {
            url: `/home${shopSuffix}`,
            label: 'Home',
            icon: HomeMinor,
          },
          {
            url: `/discounts${shopSuffix}`,
            label: 'Discounts',
            icon: DiscountsMinor
          },
          {
            url: `/waitlists${shopSuffix}`,
            label: 'Waitlists',
            icon: ClockMinor,
          },
          {
            url: `/locations${shopSuffix}`,
            label: 'Locations',
            icon: LocationsMinor,
          },
          {
            url: `/cache${shopSuffix}`,
            label: 'Cache Management',
            icon: RefreshMinor,
          },
        ]}
      />
    </Navigation>)
  return (
    <AppProvider i18n={enTranslations}>
      <Frame navigation={window.location.pathname === '/install' ? null : navigation}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Guard validateSession><Home /></Guard>} />
            <Route path="/home" element={<Guard validateSession><Home /></Guard>} />
            <Route path="/discounts" element={<Guard validateSession><Discounts /></Guard>} />
            <Route path="/locations" element={<Guard validateSession>{<Locations />}</Guard>} />
            <Route path="/waitlists" element={<Guard validateSession>{<Waitlists />}</Guard>} />
            <Route path="/cache" element={<Guard validateSession>{<CachePage />}</Guard>} />
            <Route path="/install" element={<InstallTemplate />} />
            <Route
              path="*"
              element={
                <main style={{ padding: "1rem" }}>
                  <p>There's nothing here!</p>
                </main>
              }
            />
          </Routes>
        </BrowserRouter>
      </Frame>
    </AppProvider>
  );
}

export default App;
