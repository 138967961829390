import { Banner, Button, Checkbox, Collapsible, Combobox, FormLayout, Icon, Listbox, Select, Modal, Stack, Subheading, Tag, TextContainer, TextField, TextStyle } from "@shopify/polaris";
import { SearchMinor } from "@shopify/polaris-icons";
import React, { useCallback, useEffect, useState } from "react";
import { graphql, restApi } from "../utils/session";

const TIMEZONE = '-04:00';

const DiscountModal = ({open, metafieldKey, onClose}: DiscountModalProps) => {
  const [code, setCode] = React.useState('');
  const [selectedCalendars, setSelectedCalendars] = useState<string[]>([]);
  const [selectedTypes, setSelectedTypes] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [config, setConfig] = useState<any>(null);

  const [calendarSearchValue, setCalendarSearchValue] = useState('');
  const [calendarOptions, setCalendarOptions] = useState<string[]>([]);
  const [typeSearchValue, setTypeSearchValue] = useState('');
  const [typeOptions, setTypeOptions] = useState<string[]>([]);
  const [isActive, setIsActive] = useState(true);
  const [isAdvancedOptionsOpen, setIsAdvancedOptionsOpen] = useState(true);
  const [daysOfWeek, setDaysOfWeek] = useState<Record<string, {max?:number|null}>>({});
  const [discountTitle, setDiscountTitle] = useState('');
  const [discountDescription, setDiscountDescription] = useState('');
  const [activeFrom ,setActiveFrom] = useState('');
  const [activeTo ,setActiveTo] = useState('');
  const [usesCustomDiscount, setUsesCustomDiscount] = useState<boolean>(false);
  const [showInTimeslot, setShowInTimeslot] = useState<boolean>(true);
  const [discountAmount, setDiscountAmount] = useState<number>(0);
  const [discountType, setDiscountType] = useState<'percent'|'fixed'>('percent');
  const [hideDiscountInSummary, setHideDiscountInSummary] = useState<boolean>(false);

  const updateCalendarSearchValue = useCallback((value) => {
    setCalendarSearchValue(value);
    if (value === '') {
      setCalendarOptions([]);
      return;
    }
    const filterRegex = new RegExp(value, 'i');
    const allCalendarNames = [...(config?.calendars || []).map((calendar:any) => calendar.name), ...selectedCalendars].filter((name, index, arr) => name && arr.indexOf(name) === index);
    
    const resultOptions = allCalendarNames.filter((calendar:any) =>
      filterRegex.test(calendar),
    );
    setCalendarOptions(resultOptions);
  }, [config, selectedCalendars]);

  const updateTypeSearchValue = useCallback((value) => {
    setTypeSearchValue(value);
    if (value === '') {
      setTypeOptions([]);
      return;
    }
    const filterRegex = new RegExp(value, 'i');
    const allTypeNames = [...(config?.appointmentTypes || []).map((type:any) => type.name), ...selectedTypes].filter((name, index, arr) => name && arr.indexOf(name) === index);
    
    const resultOptions = allTypeNames.filter((calendar:any) =>
      filterRegex.test(calendar),
    );
    setTypeOptions(resultOptions);
  }, [config, selectedTypes]);

  const setCodeAndValidate = useCallback((value) => {
    setCode(`${value}`.trim().split(' ').join('-').toUpperCase());
  }, []);

  const [metafieldId, setMetafieldId] = useState<any>(null);
    


  useEffect(() => {
    const run = async () => {
      setMetafieldId(null);
      setIsLoading(true)
      setSelectedCalendars([]);
      setCode('');
      setIsActive(true);
      setError('');
      setSelectedTypes([]);
      setActiveFrom('');
      setActiveTo('');
      setDaysOfWeek({});
      setDiscountTitle('');
      setDiscountDescription('');
      setUsesCustomDiscount(false);
      setDiscountAmount(0);
      setDiscountType('percent');
      setShowInTimeslot(true);
      setHideDiscountInSummary(false);

      if (!config) {
        setConfig(await (await fetch('/acuity/app-config')).json());
      }
      if (metafieldKey) {
        const metafieldData = await restApi(`/metafields.json?namepsace=pact_acuity_discounts&key=${encodeURIComponent(metafieldKey)}`);
        const metafield = metafieldData.metafields?.[0] || {}; 
        setMetafieldId(metafield.id);
        setCode(metafieldKey);
        const data = JSON.parse(metafield.value || '{}');
        setSelectedCalendars(data.calendars || []);
        setSelectedTypes(data.types || []);
        setIsActive(!!data.active);
        // remove TIMEZONE from dates so that the TextField can render them properly
        setActiveFrom(data.activeFrom?.replace(TIMEZONE, '') || '');
        setActiveTo(data.activeTo?.replace(TIMEZONE, '') || '');
        setDaysOfWeek(data.daysOfWeek || {});
        setDiscountTitle(data.title || '');
        setDiscountDescription(data.description || '');
        setUsesCustomDiscount(typeof data.usesCustomDiscount !== "undefined" ? data.usesCustomDiscount : false);
        setDiscountAmount(data.amount || 0);
        setDiscountType(data.discountType || 'fixed');
        setShowInTimeslot(typeof data.showInTimeslot !== "undefined" ? data.showInTimeslot : true);
        setHideDiscountInSummary(typeof data.hideDiscountInSummary !== "undefined" ? data.hideDiscountInSummary : false);
      }
      setIsLoading(false)
    }
    run();
  }, [metafieldKey])

  const updateTypeSelection = useCallback(
    (selected) => {
      if (selectedTypes.includes(selected)) {
        setSelectedTypes(
          selectedTypes.filter((option) => option !== selected),
        );
      } else {
        setSelectedTypes([...selectedTypes, selected]);
      }

      const matchedOption = typeOptions.find((option) => {
        return option.match(selected);
      });

      updateTypeSearchValue('');
    },
    [typeOptions, selectedTypes, updateTypeSearchValue],
  );

  const typeOptionsMarkup =
  typeOptions.length > 0
    ? typeOptions.sort((a, b) => a.localeCompare(b)).map((option) => {

        return (
          <Listbox.Option
            key={`${option}`}
            value={option}
            selected={selectedTypes.includes(option)}
            accessibilityLabel={option}
          >
            {option}
          </Listbox.Option>
        );
      })
    : null;

  const updateCalendarSelection = useCallback(
    (selected) => {
      if (selectedCalendars.includes(selected)) {
        setSelectedCalendars(
          selectedCalendars.filter((option) => option !== selected),
        );
      } else {
        setSelectedCalendars([...selectedCalendars, selected]);
      }

      const matchedOption = calendarOptions.find((option) => {
        return option.match(selected);
      });

      updateCalendarSearchValue('');
    },
    [calendarOptions, selectedCalendars, updateCalendarSearchValue],
  );

  const calendarOptionsMarkup =
  calendarOptions.length > 0
    ? calendarOptions.sort((a, b) => a.localeCompare(b)).map((option) => {

        return (
          <Listbox.Option
            key={`${option}`}
            value={option}
            selected={selectedCalendars.includes(option)}
            accessibilityLabel={option}
          >
            {option}
          </Listbox.Option>
        );
      })
    : null;

  return (
    <Modal
      open={typeof open !== 'undefined' ? open : false}
      onClose={onClose}
      title={`${metafieldId ? 'Edit' : 'Add'} Discount Code`}
      primaryAction={{
        content: 'Save',
        loading: isLoading,
        disabled: !code || !`${code}`.trim(),
        onAction: async () => {
          setIsLoading(true);
          const preexistingMetafield = await restApi(`/metafields.json?namespace=pact_acuity_discounts&key=${encodeURIComponent(code)}`);
          if (preexistingMetafield.metafields.length && (!metafieldId || preexistingMetafield.metafields[0].id !== metafieldId)) {
            setError('A discount code with that name already exists.');
            setIsLoading(false);
            return;
          }
          const payload = {
            calendars: selectedCalendars,
            types: selectedTypes,
            activeFrom: !!activeFrom ? activeFrom + TIMEZONE : null,
            activeTo: !!activeTo ? activeTo + TIMEZONE : null,
            active: isActive,
            daysOfWeek,
            title: discountTitle,
            description: discountDescription,
            showInTimeslot,
            usesCustomDiscount,
            amount: discountAmount,
            discountType,
            hideDiscountInSummary
          }
          const response = await restApi(`/metafields${metafieldId ? `/${metafieldId}` : ''}.json`, {
            metafield: {
              namespace: 'pact_acuity_discounts',
              key: code,
              value: JSON.stringify(payload),
              description: `${code}`,
              type: 'json'
            }
          }, metafieldId ? 'PUT' : 'POST');
          if (response.errors) {
            let newError = ''
            Object.keys(response.errors).forEach((key) => {
              newError += response.errors[key].map((v:string) => `${key}: ${v}`).join(', ');
            });
            setError(newError);
            setIsLoading(false);
            return;
          }
          onClose?.();
          setIsLoading(false);
          setCode('');
          setSelectedCalendars([]);
          setSelectedTypes([]);
          setActiveFrom('');
          setActiveTo('');
          setDaysOfWeek({});
          setDiscountTitle('');
          setDiscountDescription('');
        },
      }}
      secondaryActions={[
        {
          content: 'Cancel',
          onAction: () => {
            onClose?.();
          },
        },
      ]}
    >
      <Modal.Section>
        <FormLayout>
          {error && 
          <Banner status="critical" title="Discount could not be saved" onDismiss={() => setError('')}>
            <p>{error}</p>
          </Banner>
          }
          <TextField
            label="Discount Code"
            autoComplete="off"
            value={code}
            onChange={setCodeAndValidate}
            maxLength={30}
            readOnly={!!metafieldKey}
            disabled={!!metafieldKey}
            requiredIndicator
            helpText="Codes can't be changed after they're created."
          />
          <Combobox
            allowMultiple
            activator={
              <Combobox.TextField
                prefix={<Icon source={SearchMinor} />}
                onChange={updateCalendarSearchValue}
                autoComplete="off"
                label="Locations to apply discount to"
                labelHidden
                value={calendarSearchValue}
                placeholder="Search locations"
              />
            }
          >
            {calendarOptionsMarkup ? (
              <Listbox onSelect={updateCalendarSelection}>{calendarOptionsMarkup}</Listbox>
            ) : null}
          </Combobox>
          <TextContainer>
            {selectedCalendars?.length ? <Stack>{
            selectedCalendars.map((option) => (
              <Tag key={`option-${option}`} onRemove={() => setSelectedCalendars(selectedCalendars.filter(name => name !== option))}>
                {option}
              </Tag>
              ))}
            </Stack> : <p>All calendars are allowed by default, as long as they are set to allow discount codes.</p>}
          </TextContainer>

          <Combobox
            allowMultiple
            activator={
              <Combobox.TextField
                prefix={<Icon source={SearchMinor} />}
                onChange={updateTypeSearchValue}
                autoComplete="off"
                label="Appointment Types to apply discount to"
                labelHidden
                value={typeSearchValue}
                placeholder="Search appointment types"
              />
            }
          >
            {typeOptionsMarkup ? (
              <Listbox onSelect={updateTypeSelection}>{typeOptionsMarkup}</Listbox>
            ) : null}
          </Combobox>
          <TextContainer>
            {selectedTypes?.length ? <Stack>{
            selectedTypes.map((option) => (
              <Tag key={`option-${option}`} onRemove={() => setSelectedTypes(selectedTypes.filter(name => name !== option))}>
                {option}
              </Tag>
              ))}
            </Stack> : <p>All appointment types are allowed by default, as long as they are set to allow discount codes.</p>}
          </TextContainer> 
          <Checkbox
            label="Discount is active"
            checked={isActive}
            onChange={setIsActive}
          />
          <Checkbox 
            label="Hide discount in price summary"
            checked={hideDiscountInSummary}
            onChange={setHideDiscountInSummary}
          />
          <TextField type="datetime-local" autoComplete="off" label="Discount Active From" value={activeFrom} max={activeTo} onChange={setActiveFrom} />
          <TextField type="datetime-local" autoComplete="off" label="Discount Active To" value={activeTo} min={activeFrom} onChange={setActiveTo}   />
        </FormLayout>
      </Modal.Section>
      <Modal.Section>
        <Stack vertical>
          <Button
            onClick={() => setIsAdvancedOptionsOpen(!isAdvancedOptionsOpen)}
            ariaExpanded={isAdvancedOptionsOpen}
            ariaControls="advanced-collapsible"
            plain
            disclosure={isAdvancedOptionsOpen ? 'up' : 'down'}
          >
            Advanced options
          </Button>
          <Collapsible
            open={isAdvancedOptionsOpen}
            id="advanced-collapsible"
            transition={{duration: '500ms', timingFunction: 'ease-in-out'}}
            expandOnPrint
          >
            <FormLayout>
            <TextField
              label="Daily Special Promo Title"
              autoComplete="off"
              value={discountTitle}
              onChange={setDiscountTitle}
            />
            <TextField
              label="Daily Special Promo Details"
              autoComplete="off"
              multiline
              value={discountDescription}
              onChange={setDiscountDescription}
            />
            <Checkbox 
              label="Show Discount in time slots"
              checked={showInTimeslot}
              onChange={setShowInTimeslot}  
            />
            <Checkbox
              label="Uses Custom Discount"
              checked={usesCustomDiscount}
              onChange={setUsesCustomDiscount}
              helpText="Make sure that the promo title and details reflect this accurately if using a custom value" 
            />
            {usesCustomDiscount && (
              <TextField 
                type="number"
                label="Discount Value"
                autoComplete="off"
                value={discountAmount.toString()}
                onChange={(value) => setDiscountAmount(+value)} 
              />
            )}
            {usesCustomDiscount && (
              <Select
                label="Discount Type"
                options={[
                  { label: "Percent", value: "percent"},
                  { label: "Fixed", value: "fixed" }
                ]} 
                value={discountType}
                onChange={(value) => setDiscountType(value as typeof discountType)} 
              />
            )}
            <TextContainer spacing="loose">
              <p style={{marginBottom: '1rem'}}>Which (next) DOTW this discount is valid for. <br /><TextStyle variation="subdued">*Enable an optional threshold by providing # of appointment slots (for valid appointment types) that must be available for the discount to be redeemable. i.e. enter 5 and the discount will be redeemable until there are only 5 available slots left for the day for the valid appointment type(s).</TextStyle></p>
            </TextContainer>
              {['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].map((day) => (
                <Stack distribution="fillEvenly" alignment="center" key={day}>
                  <Checkbox
                    label={day}
                    checked={!!daysOfWeek[day]}
                    onChange={(checked) => {
                      if (checked) {
                        setDaysOfWeek({ ...daysOfWeek, [day]: {
                          max: null
                          }
                        });
                      } else {
                        const newDaysOfWeek = { ...daysOfWeek };
                        delete newDaysOfWeek[day];
                        setDaysOfWeek({ newDaysOfWeek });
                      }
                    }}
                  />
                  {daysOfWeek[day] && 
                    <div style={{maxWidth: '75px'}}>
                      <TextField
                      type="number"
                      label="Max slots"
                      autoComplete="off"
                      value={`${daysOfWeek[day].max || ''}`}
                      onChange={(value) => {
                        const newDaysOfWeek = { ...daysOfWeek };
                        newDaysOfWeek[day].max = value ? parseInt(value) : null;
                        setDaysOfWeek({ ...newDaysOfWeek });
                      }}
                    />
                  </div>
                  }
                </Stack>
              ))}
            </FormLayout>
          </Collapsible>
        </Stack>
      </Modal.Section>
      {metafieldId && <Modal.Section>
        <TextContainer>
          <Subheading>Deleting this discount</Subheading>
          <p>
            Deleting this discount will remove it from all future bookings. It will not affect any bookings that have already been made.
          </p>
          <p>
            This action cannot be undone.
          </p>
        <p>
        <Button
          destructive
          loading={isLoading}
          disabled={isLoading}
          onClick={async () => {
            if (!window.confirm('Are you sure you want to delete this discount? This action cannot be undone.')) {
              return;
            }
            setIsLoading(true);
            const response = await restApi(`/metafields/${metafieldId}.json`, null, 'DELETE');
            if (response.errors) {
              let newError = ''
              Object.keys(response.errors).forEach((key) => {
                newError += response.errors[key].map((v:string) => `${key}: ${v}`).join(', ');
              });
              setError(newError);
              setIsLoading(false);
              return;
            }
            onClose?.();
            setIsLoading(false);
          }}
          plain>
          Delete discount
          </Button>
          </p>
        </TextContainer>

      </Modal.Section>}
    </Modal>
  )
}

export default DiscountModal;
export interface DiscountModalProps {
  metafieldKey?: any;
  open?: boolean;
  onClose?: any;
}