import { Layout, Card, Page, TextStyle, SettingToggle, Stack, List } from "@shopify/polaris";
import React, { useEffect, useMemo, useState } from "react";
import { restApi } from "../utils/session";


const Locations = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [calendars, setCalendars] = useState([]);
  const [appointmentTypes, setAppointmentTypes] = useState([]);
  const [configData, setConfigData] = useState(null);
  const [lastUpdateAt, setLastUpdateAt] = useState(new Date());
  useEffect(() => {
    const run = async () => {
      setIsLoading(true);
      if (!calendars || calendars.length === 0) {
        setCalendars(await (await fetch('/acuity/calendars')).json());
      }
      if (!appointmentTypes || appointmentTypes.length === 0) {
        setAppointmentTypes(await (await fetch('/acuity/appointment-types')).json());
      }
      const config = await restApi('/metafields.json?namespace=pact_acuity&key=config');
      if (config && config.metafields && config.metafields.length) {
        setConfigData(JSON.parse(config.metafields[0].value));
      }
      setIsLoading(false);
    }
    run();
  }, [lastUpdateAt]);

  const groupedCalendars = useMemo(() => {
    return calendars.filter((calendar: any, index, originalArray) => {
      return originalArray.findIndex((item: any) => item.name === calendar.name) === index;
    }).sort((a:any, b:any) => a.name.localeCompare(b.name))
  }, [calendars])

  return (
    <Page title={"Locations"}>
      <Layout>
        <Layout.Section>
          {groupedCalendars.map((calendar: any) => {
            const { id, name } = calendar;
            const metafield:any = configData && configData[name];
            const actions = [
              // {
              //   title: 'Enabled in frontend',
              //   key: 'enabled',
              // },
              {
                title: 'Accepts waitlists',
                key: 'acceptsWaitlists',
              },
              {
                title: 'Accepts discounts',
                key: 'acceptsDiscounts',
              },
            ]
            const matchingAppointmentTypes = appointmentTypes.filter((appointmentType: any) => {
              return appointmentType.calendarIDs?.includes(id);
              });
              return (
                <Card key={id} title={name} sectioned>
                  <Stack>
                    <Stack.Item fill>
                      <h3>
                        <TextStyle variation="strong">Appointment Types</TextStyle>
                      </h3>
                      <List type="bullet">
                        {matchingAppointmentTypes.sort((a:any, b:any) => a.name.localeCompare(b.name)).map((appointmentType: any) => {
                          return (
                            <List.Item key={appointmentType.id}>
                              {appointmentType.name}
                            </List.Item>
                          )})}
                      </List>
                    </Stack.Item>
                    <Stack.Item>
                      {actions.map((action) => {
                        return (
                          <SettingToggle
                            key={action.key}
                            action={{
                              content: metafield?.config?.[action.key] ? 'Disable' : 'Enable',
                              loading: isLoading,
                              destructive: metafield?.config?.[action.key],
                              onAction: async () => {
                                const newConfigData = {
                                  ...(configData || {}),
                                  [name]: {
                                    ...metafield,
                                    config: {
                                      ...metafield?.config,
                                      [action.key]: !metafield?.config?.[action.key],
                                    }
                                  }
                                }
                                setIsLoading(true);
                                const newMetafieldData = await restApi('/metafields.json', {
                                  metafield: {
                                    namespace: 'pact_acuity',
                                    key: 'config',
                                    value: JSON.stringify(newConfigData),
                                    type: 'json',
                                  }
                                })
                                setLastUpdateAt(new Date());
                              },
                            }}
                            enabled={metafield?.config?.[action.key]}
                          >
                            {action.title}
                          </SettingToggle>
                        )
                      })
                      }
                    </Stack.Item>
                  </Stack>
                </Card>
              )})}
        </Layout.Section>
      </Layout>
    </Page>
  );
}

export default Locations;